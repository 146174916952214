import { useEffect, useState } from 'react';
import Web3 from 'web3';
import { changeNetworkIfNeeded } from './utils/web3';
import { ConnectWalletBtn } from './components/ConnectWalletBtn';
import { MintAmountSelector } from './components/MintAmountSelector';
import { CHAIN_ID_TO_USE, CONTRACT_ABI, CONTRACT_ADDRESS, INFURA_URL, PRIMARY_BUTTON_STYLES } from './utils/constants';
import { Toaster, toast } from 'react-hot-toast';

function App() {
  const [web3, setWeb3] = useState();
  const [account, setAccount] = useState();
  const [mintAmount, setMintAmount] = useState(1);
  const [contract, setContract] = useState();
  const [mintAmountLimit, setMintAmountLimit] = useState();
  const [mintPrice, setMintPrice] = useState();
  const [maxSupply, setMaxSupply] = useState();
  const [isPublicMintOpen, setIsPublicMintOpen] = useState();
  const [isPrivateMintOpen, setIsPrivateMintOpen] = useState();
  const [nftsAlreadyMinted, setNftsAlreadyMinted] = useState();
  const [mintTransactionHash, setMintTransactionHash] = useState();

  useEffect(() => {
    const infuraWeb3 = new Web3(new Web3.providers.HttpProvider(INFURA_URL));
    // get the contract
    setContract(new infuraWeb3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS, { handleRevert: true }));
  }, []);

  useEffect(() => {
    const fetchContractValues = async () => {
      const [limit, price, supply, publicMintOpen, privateMintOpen, count] = await Promise.all([
        contract.methods.mintAmountLimit().call(),
        contract.methods.mintPrice().call(),
        contract.methods.maxSupply().call(),
        contract.methods.isPublicMintOpen().call(),
        contract.methods.isPrivateMintOpen().call(),
        contract.methods.tokenCounter().call(),
      ]);

      setMintAmountLimit(limit);
      setMintPrice(Web3.utils.fromWei(price, 'ether'));
      setMaxSupply(supply);
      setIsPublicMintOpen(publicMintOpen);
      setIsPrivateMintOpen(privateMintOpen);
      setNftsAlreadyMinted(count);
    };

    if (contract) fetchContractValues();
  }, [contract]);

  const onConnect = async (provider) => {
    if (!provider) return;
    const newWeb3 = new Web3(provider);
    setWeb3(newWeb3);
    // get connected account and display it
    const accounts = await newWeb3.eth.getAccounts();
    setAccount(accounts[0]);
    // check the network and switch to the right one if needed
    const currentChainId = await newWeb3.eth.getChainId();
    await changeNetworkIfNeeded(newWeb3, currentChainId);
    // react when network changes to ask the user to select the right one if needed
    newWeb3.currentProvider.on('chainChanged', async (chainId) => {
      await changeNetworkIfNeeded(newWeb3, chainId);
    });
    // react when the connected account changes to always have the right one in the state
    newWeb3.currentProvider.on('accountsChanged', (accounts) => {
      setAccount(accounts[0]);
    });
  };

  const onMintClick = async () => {
    const currentChainId = await web3.eth.getChainId(); // fetch the current network
    await changeNetworkIfNeeded(web3, currentChainId); // change the network if needed
    const newChainId = await web3.eth.getChainId(); // fetch the new network to make sure the user changed the network
    if (newChainId !== CHAIN_ID_TO_USE) return; // if the user didn't change the network, display error

    // send the transaction and let the user confirm on their wallet
    const txParams = {
      from: account, // send the transaction from the connected wallet
      to: CONTRACT_ADDRESS, // send the transaction to the contract
      value: Web3.utils.toHex(Web3.utils.toWei(`${mintPrice * mintAmount}`, 'ether')), // the amount of ETH to pay
      data: contract.methods.mint(mintAmount).encodeABI(), // encode the mint method call in a readable way for the contract
    };
    web3.eth
      .sendTransaction(txParams)
      .on('transactionHash', (hash) => {
        // once the transaction is sent, inform the user that they have to wait
        toast.success('The mint transaction was sent, hold on until it succeeds...');
        setMintTransactionHash(hash);
      })
      .on('receipt', (receipt) => {
        // if the transaction succeeds, inform user
        if (receipt.status) {
          toast.success('The mint was successful!');
        }
        console.log(receipt);
      })
      .on('error', async (error) => {
        // if the transaction fails, display the error
        if (!error.receipt) return toast.error('The mint failed');

        const tx = await web3.eth.getTransaction(error.receipt.transactionHash);

        try {
          await web3.eth.call(tx, tx.blockNumber); // will fail because the transaction failed and the error will give us the revert reason
        } catch (err) {
          console.log(err.message); // will look like "execution reverted : revert reason here { details here }"
          const revertReason = err.message.split('{')[0].split(':')[1];
          toast.error(`The mint failed: ${revertReason}`);
          return;
        }

        toast.error('The mint failed');
      });
  };

  return (
    <div className="flex flex-col h-screen justify-center items-center">
      <div>
        <Toaster />
      </div>
      {account ? (
        <>
          <span className="mt-4">Connected with: {account}</span>
          <span className="mt-4 mb-8">
            {nftsAlreadyMinted - 1}/{maxSupply} minted NFTs
          </span>
          <MintAmountSelector
            mintAmount={mintAmount}
            setMintAmount={setMintAmount}
            mintAmountLimit={mintAmountLimit ? parseInt(mintAmountLimit) : null}
            mintPrice={mintPrice}
          />
          <button
            className={PRIMARY_BUTTON_STYLES + ' mt-4'}
            disabled={(!isPublicMintOpen && !isPrivateMintOpen) || maxSupply === nftsAlreadyMinted - 1}
            onClick={onMintClick}
          >
            Mint
          </button>
          {mintTransactionHash && (
            <a
              href={`https://goerli.etherscan.io/tx/${mintTransactionHash}`}
              target="_blank"
              rel="noreferrer"
              className="mt-8 underline"
            >
              See your mint transaction
            </a>
          )}
        </>
      ) : (
        <ConnectWalletBtn setProvider={onConnect} />
      )}
    </div>
  );
}

export default App;
