import WalletConnectProvider from '@walletconnect/web3-provider';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import Torus from '@toruslabs/torus-embed';

const getProviderOptions = (isMainnet = false) => ({
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: 'fa204cf6b925448a9ca71d3831ac638e',
    },
  },
  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: 'MyNFT Mint Website',
      infuraId: 'fa204cf6b925448a9ca71d3831ac638e',
      chainId: isMainnet ? 1 : 5,
    },
  },
  torus: {
    package: Torus, // required
    options: {
      networkParams: {
        host: isMainnet ? 'mainnet' : 'goerli',
      },
    },
  },
});

export default getProviderOptions;
